import OBSWebSocket, {OBSRequestTypes, OBSResponseTypes} from 'obs-websocket-js';
import {OBS_CONFIG, PANEL_VIDEO_GROUP} from "../config/obs-settings";
import {StreamConfig} from "../types";
import {VISUALIZER_TAG, VISUALIZER_TAGBG} from "../config/constants";


const OBS_DEBUG_OUTPUT = false;

export class OBS {
    private readonly obsWebSocket: OBSWebSocket;
    private TrackerConfig: Map<string, StreamConfig> | undefined;

    constructor() {
        this.obsWebSocket = new OBSWebSocket();

        this.obsWebSocket.on('ConnectionOpened', () => {
            console.log('Connected to OBS WebSocket');
        });

        this.obsWebSocket.on('ConnectionError', (...args: [] | [any]) => {
            console.error('OBS WebSocket Error:', args);
        });

        this.obsWebSocket.on('ConnectionClosed', () => {
            console.log('Disconnected from OBS WebSocket');
        });
    }

    public obs() {
        return this.obsWebSocket;
    }

    public async connect(endpoint?: string, password?: string): Promise<void> {
        try {
            console.log("Connecting to OBS")
            await this.obsWebSocket.connect(`${endpoint}`, password).then((info) => {
                console.log('Connected and identified', info)
            })
        } catch (error) {
            console.error('Error connecting to OBS WebSocket:', error);
            throw error;
        }
    }

    public async disconnect(): Promise<void> {
        return this.obsWebSocket.disconnect()
    }

    public async sendCommand<Type extends keyof OBSRequestTypes>(requestType: Type, requestData?: OBSRequestTypes[Type], silent?: Boolean): Promise<OBSResponseTypes[Type]> {
        try {
            if (silent !== true && OBS_DEBUG_OUTPUT) {
                console.log('Sending command:', requestType, 'with params:', requestData)
            }
            return await this.obsWebSocket.call(requestType, requestData)
        } catch (error) {
            console.log('Error sending command', requestType, ' - error is:', error)
            throw error
        }
    }

    public async getSceneList(): Promise<any> {
        try {
            return await this.sendCommand('GetSceneList');
        } catch (error) {
            console.error('Error getting scene list:', error);
            throw error;
        }
    }

    public async getCurrentProgramScene(): Promise<any> {
        const GetCurrentScene = await this.sendCommand("GetCurrentProgramScene")
        return GetCurrentScene.currentProgramSceneName
    }

    public async getCurrentPreviewScene(): Promise<any> {
        const GetCurrentPreviewScene = await this.sendCommand("GetCurrentPreviewScene")
        return GetCurrentPreviewScene.currentPreviewSceneName
    }

    public async setCurrentScene(sceneName: string): Promise<void> {
        try {
            await this.sendCommand('SetCurrentProgramScene', { sceneName: sceneName });
        } catch (error) {
            console.error('Error setting current scene:', error);
            throw error;
        }
    }

    public async getVolume(inputName: string): Promise<any> {
        return await this.sendCommand('GetInputVolume', {inputName: inputName})
    }

    public async setVolumeDb(inputName: string, inputVolumeDb:number): Promise<any> {
        await this.sendCommand('SetInputVolume', {inputName: inputName, inputVolumeDb: inputVolumeDb })
    }

    public async startStreaming(): Promise<void> {
        try {
            await this.sendCommand('StartStream');
        } catch (error) {
            console.error('Error starting streaming:', error);
            throw error;
        }
    }

    public async stopStreaming(): Promise<void> {
        try {
            await this.sendCommand('StopStream');
        } catch (error) {
            console.error('Error stopping streaming:', error);
            throw error;
        }
    }

    public async startRecording(): Promise<void> {
        try {
            await this.sendCommand('StartRecord');
        } catch (error) {
            console.error('Error starting streaming:', error);
            throw error;
        }
    }

    public async stopRecording(): Promise<void> {
        try {
            await this.sendCommand('StopRecord');
        } catch (error) {
            console.error('Error starting streaming:', error);
            throw error;
        }
    }

    public async refreshBrowser(inputName: string): Promise<void> {
        await this.sendCommand("PressInputPropertiesButton", {inputName: inputName, propertyName: "refreshnocache"});
    }

    public async toggleVideo(sourceName: string): Promise<void> {
        try {
            // Hide all panel videos
            const PanelSceneList = await this.sendCommand("GetGroupSceneItemList", {sceneName: PANEL_VIDEO_GROUP});
            for (let sceneItems of PanelSceneList.sceneItems) {

                if (sceneItems.sceneItemEnabled === null || sceneItems.sceneItemId === null ) {
                    console.log('Missing key info')
                    break;
                }

                if (sceneItems.sceneItemEnabled === true) {
                    if (sceneItems.sourceName !== sourceName) {
                        await this.sendCommand("SetSceneItemEnabled", {
                            sceneName: PANEL_VIDEO_GROUP,
                            sceneItemId: Number(sceneItems.sceneItemId),
                            sceneItemEnabled: false
                        });
                    }
                } else {
                    if (sceneItems.sourceName === sourceName) {
                        await this.sendCommand("SetSceneItemEnabled", {
                            sceneName: PANEL_VIDEO_GROUP,
                            sceneItemId: Number(sceneItems.sceneItemId),
                            sceneItemEnabled: true
                        });
                    }
                }
            }
        } catch (error) {
            console.error('Error hiding all videos:', error);
            throw error;
        }
    }

    public async setVisualizerTag(tag: string) {
        // const vis = await this.sendCommand("GetInputSettings", {inputName:VISUALIZER_TAG})
        // console.log("old: ", vis)
        const newTextSettings = {text: tag }
        await this.sendCommand("SetInputSettings", {inputName:VISUALIZER_TAG, inputSettings:newTextSettings})
        await this.sendCommand("SetInputSettings", {inputName:VISUALIZER_TAGBG, inputSettings:newTextSettings})
    }

    public async setVideo(type: string, key: string) {
        if(this.TrackerConfig) {
            const config = this.TrackerConfig.get(type)
            if(config) {
                console.log(config.type)

                if(config.inputType === 'webrtc') {
                    const newSettings = {url : config.path + key}
                    await this.sendCommand("SetInputSettings", {inputName: config.source, inputSettings: newSettings })
                    await this.toggleVideo(config.source)
                } else if (config.inputType === 'srt') {
                    const newSettings = {input : config.path + key}
                    await this.sendCommand("SetInputSettings", {inputName: config.source, inputSettings: newSettings })
                    await this.toggleVideo(config.source)
                } else if (config.inputType === 'rtmp') {
                    const newSettings = {input : config.path + key}
                    await this.sendCommand("SetInputSettings", {inputName: config.source, inputSettings: newSettings })
                    await this.toggleVideo(config.source)

                } else if (config.inputType === 'video') {
                    const newSettings = {local_file : config.path + key}
                    await this.sendCommand("SetInputSettings", {inputName: config.source, inputSettings: newSettings })
                    await this.toggleVideo(config.source)

                    // We flush the media buffer to clear it out
                    await this.sendCommand("TriggerMediaInputAction", {inputName: config.source, mediaAction: "OBS_WEBSOCKET_MEDIA_INPUT_ACTION_STOP"})
                    // We need to wait till the video system process the file
                    await new Promise(f => setTimeout(f, 1000));
                    // We restart to load the file in and pause it immediately after
                    await this.sendCommand("TriggerMediaInputAction", {inputName: config.source, mediaAction: "OBS_WEBSOCKET_MEDIA_INPUT_ACTION_RESTART"})
                    await this.sendCommand("TriggerMediaInputAction", {inputName: config.source, mediaAction: "OBS_WEBSOCKET_MEDIA_INPUT_ACTION_PAUSE"})
                } else {
                    console.log('Unknown Type')
                }

            }
        } else {
            console.log("Cannot find Tracker Config, using default")
            //Fallback
            if (OBS_CONFIG[type].inputType === 'webrtc') {
            console.log('webrtc')
            const newSettings = {url : OBS_CONFIG[type].path + key}
            await this.sendCommand("SetInputSettings", {inputName: OBS_CONFIG[type].source, inputSettings: newSettings })
            await this.toggleVideo(OBS_CONFIG[type].source)

            } else if (OBS_CONFIG[type].inputType === 'srt') {
                console.log('srt')
                const newSettings = {input : OBS_CONFIG[type].path + key}
                await this.sendCommand("SetInputSettings", {inputName: OBS_CONFIG[type].source, inputSettings: newSettings })
                await this.toggleVideo(OBS_CONFIG[type].source)

            } else if (OBS_CONFIG[type].inputType === 'rtmp') {
                console.log('rtmp')
                const newSettings = {input : OBS_CONFIG[type].path + key}
                await this.sendCommand("SetInputSettings", {inputName: OBS_CONFIG[type].source, inputSettings: newSettings })
                await this.toggleVideo(OBS_CONFIG[type].source)

            } else if (OBS_CONFIG[type].inputType === 'video') {
                console.log('video')
                const newSettings = {local_file : OBS_CONFIG[type].path + key}
                await this.sendCommand("SetInputSettings", {inputName: OBS_CONFIG[type].source, inputSettings: newSettings })
                await this.toggleVideo(OBS_CONFIG[type].source)
                // Test load the media
                //await this.sendCommand("SetMediaInputCursor", {inputName: OBS_CONFIG[type].source, mediaCursor: 0 })
            } else {
                console.log('unknown')
            }
        }
    }

    public async playVideo(type: string) {
        if(this.TrackerConfig) {
            const config = this.TrackerConfig.get(type)
            if(config) {
                if(config.inputType === 'video') {
                    await this.sendCommand("TriggerMediaInputAction", {
                        inputName: config.source,
                        mediaAction: "OBS_WEBSOCKET_MEDIA_INPUT_ACTION_PLAY"
                    })
                }
            }
        }
    }

    public async pauseVideo(type: string) {
        if(this.TrackerConfig) {
            const config = this.TrackerConfig.get(type)
            if(config) {
                if(config.inputType === 'video') {
                    await this.sendCommand("TriggerMediaInputAction", {
                        inputName: config.source,
                        mediaAction: "OBS_WEBSOCKET_MEDIA_INPUT_ACTION_PAUSE"
                    })
                }
            }
        }
    }

    public async restartVideo(type: string) {
        if(this.TrackerConfig) {
            const config = this.TrackerConfig.get(type)
            if(config) {
                if(config.inputType === 'video') {
                    await this.sendCommand("TriggerMediaInputAction", {
                        inputName: config.source,
                        mediaAction: "OBS_WEBSOCKET_MEDIA_INPUT_ACTION_RESTART"
                    })
                }
            }
        }
    }

    public async enableVideoRestartOnTransition(type: string, enable: boolean) {
        if(this.TrackerConfig) {
            const config = this.TrackerConfig.get(type)
            if(config) {
                if(config.inputType === 'video') {

                    const settings = {restart_on_activate: enable }

                    // let settings = await this.sendCommand("GetInputSettings", {inputName: config.source})
                    // if(settings.inputSettings.restart_on_activate != null){
                    //
                    // }
                    await this.sendCommand("SetInputSettings", {inputName: config.source, inputSettings: settings })
                }
            }
        }
    }


    public async swapVideo(sceneName: string) {
        await this.sendCommand("SetCurrentPreviewScene", {sceneName: sceneName})
        await this.sendCommand("TriggerStudioModeTransition")

    }

    public async getPreviewScene(): Promise<string> {
        const CurrentScene = await this.sendCommand("GetCurrentPreviewScene");
        if(CurrentScene) {
            return CurrentScene.currentPreviewSceneName
        }
        return ""
    }
    public async setPreviewScene(sceneName: string): Promise<void> {
        await this.sendCommand("SetCurrentPreviewScene", {sceneName: sceneName})
    }

    public async getActiveScene(): Promise<string> {
        const CurrentScene = await this.sendCommand("GetCurrentProgramScene");
        if(CurrentScene) {
            return CurrentScene.currentProgramSceneName
        }
        return ""
    }
    public async setActiveScene(sceneName: string): Promise<void> {
        await this.sendCommand("SetCurrentProgramScene", {sceneName: sceneName})
    }

    public async getActiveSource(): Promise<string> {
        const ActivePanels = [];
        try {
            // Hide all panel videos
            const PanelSceneList = await this.sendCommand("GetGroupSceneItemList", {sceneName: PANEL_VIDEO_GROUP});
            for (let sceneItems of PanelSceneList.sceneItems) {
                if (sceneItems.sceneItemEnabled === null || sceneItems.sceneItemId === null ) {
                    break;
                }
                if (sceneItems.sceneItemEnabled === true) {
                    ActivePanels.push(sceneItems.sourceName)
                }
            }
        } catch (error) {
            console.error('Error hiding all videos:', error);
            throw error;
        }
        let panel
        if (ActivePanels.length === 1) {
            panel = String(ActivePanels[0])
        } else if (ActivePanels.length === 0) {
            panel = ""
        } else {
            console.warn('Multiple sources active, using top one')
            panel = String(ActivePanels[0])
        }
        return panel
    }

    public async getStats(): Promise<any> {
        return await this.sendCommand("GetStats")
    }

    public async getInputSettings(inputName: string): Promise<any> {
        return await this.sendCommand("GetInputSettings", {inputName: inputName })
    }

    public async getInputStatus(inputName: string): Promise<any> {
        return await this.sendCommand("GetMediaInputStatus", {inputName: inputName })
    }

    public async loadConfig(trackerConfig: Map<string, StreamConfig> | undefined) {
        if(trackerConfig) {
            this.TrackerConfig = trackerConfig
        }
    }
}
