import React, {ReactElement} from 'react';
import {Button} from "@mui/material";
import {PropTypes} from "@mui/material";
import {OBS_PATH} from "./config/constants";
import {OBS} from "./utils/obs";

interface StreamManagerProps {
    obs: OBS;
    inputName: string;
    inputType: string;
    label: string;
    color: PropTypes.Color;
}

export function StreamAudioFixer(props: StreamManagerProps): ReactElement {

    async function fixPanelAudio() {
        if(props.inputType === 'rtmp') {
            const currentSettings = (await props.obs.sendCommand("GetInputSettings", {inputName: props.inputName})).inputSettings

            await props.obs.sendCommand("SetInputSettings", {inputName: props.inputName, inputSettings: {is_local_file: true, local_file: OBS_PATH + "Streaming_Standby.png", clear_on_media_end: false}});
            setTimeout(async () => {
                // Explicitly turn off "is_local_file" in case we're racing with another attempt to do the same thing.
                await props.obs.sendCommand("SetInputSettings", {inputName: props.inputName, inputSettings: {...currentSettings, is_local_file: false}});
            }, 1000);
        }
    }

    return <Button variant="contained" onClick={() => fixPanelAudio()}>{props.label}</Button>;
}
