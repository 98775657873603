import {STREAM_TRACKER} from "../config/constants";
import {StreamConfig} from "../types";
import {StreamMap} from "../types";
import {ConfigMap} from "../types";
import {Stream} from "../types";

export class StreamUpdatedEvent extends Event {
    public readonly stream: Stream;

    constructor(eventInit: EventInit & {stream: Stream}) {
        super("streamupdated", eventInit);
        this.stream = eventInit.stream;
    }
}

export class PanelStreamTracker extends EventTarget {
    private _ready = false;
    private _mapping = null as Map<string, Stream> | null;
    private _config = null as Map<string, StreamConfig> | null;
    private _eventsource = null as EventSource | null;

    constructor(private readonly password: string) {
        super();
    }

    public close() {
        if (this._eventsource) {
            this._eventsource.close();
        }
    }

    public get ready(): boolean {
        return this._ready;
    }


    public async start() {
        await this.getStreamMapping()
        await this.getStreamConfig()
        this._ready = true;

        this._eventsource = new EventSource(STREAM_TRACKER + "/api/stream_updates?password=" + this.password);
        this._eventsource.onmessage = (e) => this.handleMessage(e);

    }

    public get mapping(): Map<string, Stream> | null {
        if (this._ready) {
            return this._mapping;
        }
        return null;
    }

    public get config(): Map<string, StreamConfig> | null {
        if (this._ready) {
            return this._config;
        }
        return null;
    }

    public async fetchStreamMapping(): Promise<Map<string, Stream>> {
        const streamMapping = new Map<string, Stream>();

        const result = await fetch(STREAM_TRACKER + "/api/streams?password=" + this.password);
        const json = await result.json() as StreamMap;
        for (const id of Object.keys(json.streams)) {
            streamMapping.set(id, json.streams[id]);
        }
        return streamMapping
    }

    public async getStreamMapping(): Promise<Map<string, Stream>> {
        if (!this._mapping) {
            this._mapping = await this.fetchStreamMapping();
            setInterval(async () => {this._mapping = await this.fetchStreamMapping()}, 30000);
        }
        return this._mapping;
    }

    public async fetchStreamConfig(): Promise<Map<string, StreamConfig>> {
        const StreamConfigs = new Map<string, StreamConfig>();

        const config = await fetch(STREAM_TRACKER + "/api/configs?password=" + this.password);
        const configJson = await config.json() as ConfigMap;

        for (const id of Object.keys(configJson.configs)) {
            StreamConfigs.set(configJson.configs[id].type, configJson.configs[id]);
        }
        return StreamConfigs
    }

    public async getStreamConfig(): Promise<Map<string, StreamConfig>> {
        if (!this._config) {
            this._config = await this.fetchStreamConfig();
            setInterval(async () => {this._config = await this.fetchStreamConfig()}, 30000);
        }
        return this._config;
    }

    private handleMessage(e: MessageEvent) {
        const [alive, stream] = e.data.split("|", 2)
        if (this._mapping) {
            if (this._mapping.has(stream)) {
                this._mapping.get(stream)!.live = Boolean(Number(alive));
                console.log("dispatching event...", this._mapping.get(stream));
                this.dispatchEvent(new StreamUpdatedEvent({stream: this._mapping.get(stream)!}));
            }
        }
    }
}
