import React, {ReactElement, useEffect, useState} from 'react';
import {StreamAudioFixer} from "./streamaudiofixer";
import {
    TECH_SOURCE,
    DEFAULT_PANEL_MODE, PANEL_SCENE, VISUALIZER_SOURCE
} from "./config/constants";
import "./css/streammanager.css";
import {StreamSchedule} from "./streamschedule";
import {StreamVolume} from "./streamvolume";
import {PanelSettings} from "./panelsettings";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {StreamVideo} from "./streamvideocontrol";
import {StreamManagerProps} from "./types";


export function StreamManager(props: StreamManagerProps): ReactElement {
    const [currentStreamType, setCurrentStreamType] = useState("");
    const [currentStreamKey, setCurrentStreamKey] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [activeImage, setActiveImage] = useState("");
    const [promptResolution, setPromptResolution] = useState(null as null | {r: (x: boolean) => void});
    const [promptBrowserRefresh, setPromptBrowserRefresh] = useState(null as null | {r: (x: boolean) => void});

    const [previewScene, setPreviewScene] = useState("");
    const [activeScene, setActiveScene] = useState("");
    const [activeSource, setActiveSource] = useState(DEFAULT_PANEL_MODE);

    useEffect(() => {
        async function updateStreamURL() {
            const activeType = await props.obs.getActiveSource()
            setActiveSource(activeType)
            await updateOBSInfo()
//            const result = await props.obs.sendCommand("GetInputSettings", {inputName: OBS_CONFIG[activePanel].source});
/*            if ((result.sourceSettings as any).is_local_file) {
                const f = (result.sourceSettings as any).local_file;
                // Avoid being confused by the reboot screen.
                if (f.endsWith("Streaming_Standby.png")) {
                    return;
                }
                setCurrentLocalFile(f);
                setCurrentStreamURL("");
            } else {
                setCurrentStreamURL((result.sourceSettings as any).input);
                setCurrentLocalFile("");
            } */
            //console.log(result);
        }
        async function updateOBSInfo() {
            setPreviewScene(await props.obs.getPreviewScene())
            setActiveScene(await props.obs.getActiveScene())
        }

        const interval = setInterval(updateStreamURL, 1000);

        props.obs.obs().on("SceneTransitionEnded", updateOBSInfo);

        updateStreamURL().finally()

        return () => {
            props.obs.obs().off("SceneTransitionEnded", updateOBSInfo);
            clearInterval(interval);
        }
    }, [props.obs]);

    useEffect(() => {
       const interval = setInterval(async () => {
           try {
               const preview = await props.obs.sendCommand("GetSourceScreenshot", {sourceName: previewScene, imageFormat: "png", imageWidth: 350}, true);
               setPreviewImage(preview.imageData);

               const active = await props.obs.sendCommand("GetSourceScreenshot", {sourceName: activeScene, imageFormat: "png", imageWidth: 350}, true);
               setActiveImage(active.imageData);

               const activeType = await props.obs.getActiveSource()
               setActiveSource(activeType)
               setCurrentStreamType(activeType.toLowerCase())
           } catch(e) {
               setPreviewImage("");
               setActiveImage("")
           }
       }, 250);

       return () => {
           return clearInterval(interval);
       }
    }, [props.obs, previewScene, activeScene]);

/*    async function updateStreamURL(url: string) {
        const activePanel = await props.obs.getActiveVideo()
        const currentSettings = await props.obs.send("GetSourceSettings", {sourceName: PANEL_SOURCE});
        await props.obs.send("SetSourceSettings", {sourceName: PANEL_SOURCE, sourceSettings: {...currentSettings, input: url, url: url, is_local_file: false, restart_on_activate: false, clear_on_media_end: false}})
        setCurrentStreamURL(url);
        setCurrentLocalFile("");
        console.log("Setting Stream URL: " + url)
    }

    async function updateLocalFile(path: string) {
        const currentSettings = await props.obs.send("GetSourceSettings", {sourceName: PREREC_SOURCE});
        await props.obs.send("SetSourceSettings", {sourceName: PREREC_SOURCE, sourceSettings: {...currentSettings, local_file: path, is_local_file: true, restart_on_activate: true, clear_on_media_end: true}})
        setCurrentStreamURL("");
        setCurrentLocalFile(path)
        console.log("Setting local file: " + path)
    }*/

    function switchPrompt(): Promise<boolean> {
        return new Promise<boolean>(((resolve) => {
            setPromptResolution({r: resolve});
        }))
    }

    function refreshPrompt(): Promise<boolean> {
        return new Promise<boolean>(((resolve) => {
            setPromptBrowserRefresh({r: resolve});
        }))
    }

    /*async function updateZoomKey(key: string, enabled: boolean) {
        if (!props.zoomObs) {
            if (enabled) {
                console.error("asked to enable Zoom OBS, but can't contact it!");
            }
            return;
        }
        try {
            // We do this unconditionally in case we happened to already be streaming, because we can't
            // start a stream if we were already streaming. This should never happen, but probably will
            // anyway.
            await props.zoomObs.stopStreaming()
        } catch(e) {
            if (e.error !== "streaming not active") {
                throw e;
            }
        }
        if (enabled) {
            if (PANEL_MODE === "RTMP") {
                // In theory we can set the stream settings when starting the stream... in practice, though,
                // that does not work. do the two steps separately.
                await props.zoomObs.send("SetStreamSettings", {
                    type: "rtmp_custom",
                    settings: {
                        server: `${OUTPUT_URL}`,
                        key,
                        use_auth: false,
                    } as any, // the types think it should be use-auth instead of use_auth.
                    save: false
                });
                await props.zoomObs.startStreaming()
            } else {
                await props.zoomObs.send("SetStreamSettings", {
                    type: "rtmp_custom",
                    settings: {
                        server: `${OUTPUT_URL}` + key,
                        use_auth: false,
                    } as any, // the types think it should be use-auth instead of use_auth.
                    save: false
                });
                await props.zoomObs.startStreaming()
            }
        }
    }


    let curStreamURL = currentStreamURL.split('/').pop()!;
    if (PANEL_MODE === "SRT") {
        curStreamURL = curStreamURL.split('=').pop()!
    }*/
    async function setScene(name: string) {
        if(!props.obs) {
            return;
        }
        await props.obs.setPreviewScene(name)
        //await props.obs.sendCommand("TriggerStudioModeTransition")
    }

    async function requestBrowserRefresh(inputName: string) {
        if (!props.transitionSafe && !(await refreshPrompt())) {
            return;
        }
        await props.obs.refreshBrowser(inputName)
    }

    function isAudioFile(filename: string) : boolean {
        const ext = filename.split('.').pop();
        if (ext === "mp3" || ext === "wav" || ext === "ogg" ) {
            return true
        }
        return false
    }

    async function setVisualizer(enabled: boolean) {
        const visualizerID = await props.obs.sendCommand("GetSceneItemId", {sceneName: PANEL_SCENE,sourceName: VISUALIZER_SOURCE })
        await props.obs.sendCommand("SetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: visualizerID.sceneItemId, sceneItemEnabled:enabled})
    }

    async function requestSwitchFeed(type: string, key: string, tag: string) {
        if(!props.transitionSafe && !(await switchPrompt())) {
            return;
        }
        await props.obs.setVideo(type, key)
        await props.obs.setVisualizerTag(tag)

        setCurrentStreamType(type);
        const streamKey = key.split('/').pop()!
        setCurrentStreamKey(streamKey);

        if(isAudioFile(streamKey)) {
            console.log("Detected audio, enabling visualizer :)")
            await setVisualizer(true)
        } else {
            await setVisualizer(false)
        }

        await setScene("Panel")

        //await props.obs.swapVideo(type)
    }

    return <div className="StreamManager">
        <StreamSchedule
            room={props.room}
            activeRoom={props.activeRoom}
            password={props.password}
            muted={props.muted}
            requestMuteState={props.requestMuteState}
            currentStreamType={currentStreamType}
            currentStreamKey={currentStreamKey.split('/').pop()!}
            requestSwitchFeed={requestSwitchFeed}
            streamTracker={props.streamTracker}
            schedule={props.schedule}
        />
        <div className="StreamManager-panelpreview">
            <h4>Preview: Panel</h4>
            {previewImage !== "" ? <img src={previewImage} alt="preview of panel status" /> : "Source preview unavailable"}
        </div>
        {(currentStreamType === "prerecord") ?
            <StreamVideo
                obs={props.obs}
                inputName={"PreRecord"}
                streamTracker={props.streamTracker}
                transitionSafe={props.transitionSafe}
            />
            : <></>}
        <div className="StreamManager-fixers">
            <Button color="primary" variant="contained" onClick={() => props.obs.sendCommand("TriggerStudioModeTransition")}>Transition</Button>
            {(currentStreamType === "webrtc") ?
                <Button color="primary" variant="contained" onClick={() => {
                    //props.obs.getActiveSource().then((data) => {requestBrowserRefresh(activeSource).finally()})
                    requestBrowserRefresh(activeSource).finally()
                }}
                >Refresh WebRTC</Button>
                : <></>}
        </div>
        <div className="StreamManager-panelpreview">
            <h4>Program: Panel (Output)</h4>
            {activeImage !== "" ? <img src={activeImage} alt="preview of panel status" /> : "Source preview unavailable"}
        </div>
        <StreamVolume obs={props.obs} source={activeSource} />
        <div className="StreamManager-fixers">
            <StreamAudioFixer obs={props.obs} inputName={activeSource} inputType={currentStreamType} label="Reboot Panel" color="secondary" />
            <StreamAudioFixer obs={props.obs} inputName={TECH_SOURCE} inputType={currentStreamType} label="Reboot Tech" color="primary"/>
            {/* Todo: put this button somewhere less ridiculous. */}
            <PanelSettings obs={props.obs} currentStreamType={currentStreamType} streamTracker={props.streamTracker} />
        </div>
        <Dialog open={!!promptResolution} onClose={() => {promptResolution!.r(false); setPromptResolution(null)}}>
            <DialogTitle>Perform interrupting switch?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are trying to switch panels while a panel is being streamed. This will cut off the current panel
                    and switch immediately to another panel.
                    If you are playing a prerecorded panel, it will not be possible to resume where you left off.
                    Consider changing to non-panel scene before switching.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {promptResolution!.r(false); setPromptResolution(null)}} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {promptResolution!.r(true); setPromptResolution(null)}} color="secondary">
                    Change panel
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={!!promptBrowserRefresh} onClose={() => {promptBrowserRefresh!.r(false); setPromptBrowserRefresh(null)}}>
            <DialogTitle>Perform browser refresh?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are trying to refresh a stream. Please only refresh when you are setting up the panels to get the stream going. Please don't do it live, it will cause downtime.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {promptBrowserRefresh!.r(false); setPromptBrowserRefresh(null)}} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {promptBrowserRefresh!.r(true); setPromptBrowserRefresh(null)}} color="secondary">
                    Refresh panel
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
}
