 import React from 'react';
 import { createRoot } from "react-dom/client";
 import './css/index.css';
 import App from './App';
 import CssBaseline from "@mui/material/CssBaseline";
 import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';


// import makeStyles from '@mui/styles/makeStyles';



 // declare module '@mui/styles/defaultTheme' {
 //   // eslint-disable-next-line @typescript-eslint/no-empty-interface
 //   interface DefaultTheme extends Theme {}
 // }



 const theme = createTheme(adaptV4Theme({
     palette: {
         error: {
             main: '#f44336',
         },
         primary: {
             main: '#97b8dd',
         },
         success: {
             main: '#19c265',
         },
         secondary: {
             main: '#99b0c8',
         },
         background: {
             default: '#3e4c58',
             paper: '#1d2936',
         },
         text: {
             primary: '#99b0c8',
             secondary: '#99b0c8',
         }
     },
 overrides: {
     MuiButton: {
         containedPrimary: {
             color: '#550ce7',
             background: '#177af6',
             backgroundColor: '#97b8dd',
             fontSize: 'medium',
             fontWeight: 'bold',
         },
         containedSecondary: {
             color: '#177af6',
             background: '#e66517',
             fontSize: 'medium',
             fontWeight: 'bold',
         }
     },
     MuiRadio:{
         root: {
             color: '#97b8dd',
         },
         colorSecondary: {
             color: '#97b8dd',
         },
     },
     MuiSvgIcon:{
         colorSecondary: {
             color: '#97b8dd',
         }
     },
     MuiPaper: {
         root: {
             color: '#97b8dd',
             background: '#1d2936',
         }
     },
     MuiCardContent: {
         root: {
             color: '#5eabfb',
             background: '#1d2936',
         }
     },
     MuiSwitch: {
         switchBase: {
             color: '#891446',
         },
         colorPrimary: {
             "&.Mui-checked": {
                 // Controls checked color for the thumb
                 color: "#19c265"
             }
         },
         colorSecondary: {
             color: '#891446',
         },
         track: {
             backgroundColor: '#891446',
             opacity: 0.5,
             ".Mui-checked.Mui-checked + &": {
                 // Controls checked color for the track
                 opacity: 0.5,
                 backgroundColor: "#19c265"
             }
         }

     },
     MuiButtonBase: {
         root: {
             color: '#4f72ff',
         }
     },
     MuiListItem: {
         root: {
             "&$selected": {
                 color: '#99b0c8',
                 backgroundColor: '#4f72ff',
 //                backgroundColor: 'rgb(134,90,206)',
                 "&:hover": {
                     backgroundColor: '#4f72ff',
                 }
             }
 //            button: {
 //                "&:hover": {
 //                    backgroundColor: "yellow",
 //                },
 //            },

         }
     }
 }
 }));

 const rootElement = document.getElementById("root");
 if (!rootElement) throw new Error('Failed to find the root element');
 const root = createRoot(rootElement);

 root.render(
     <React.StrictMode>
         <StyledEngineProvider injectFirst>
             <ThemeProvider theme = { theme }>
                 <CssBaseline />
             <App />
             </ThemeProvider>
         </StyledEngineProvider>,
     </React.StrictMode>
 );

