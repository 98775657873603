import {MusicController} from "./utils/musiccontroller";
import {OBS} from "./utils/obs";
import {PanelStreamTracker} from "./utils/panelstreamtracker";
import {Schedule} from "./utils/schedule";

export interface Stream {
    key: string;
    type: string;
    live: boolean;
}

export interface StreamMap {
    streams: {[key: string]: Stream};
}

export interface ConfigMap {
    configs: {[type: string]: StreamConfig};
}

export interface StreamConfig {
    type: string;
    inputType: string;
    compressor: string;
    gain: string;
    source: string;
    path: string;
    preview: string;
    previewPost?: string;
    preview2?: string;
    output: string;
    outputPost?: string;
}

export interface ReStreams {
    name: string;
    start: string;
    stop: string;
}

export interface ObsConfig {
    name: string;
    enabled: boolean;
    inputPreviewType: string;
    outputPreviewType: string;
    endpoint: string;
    techStream: string;
    streamService: string;
    streamOutput: string;
    streamKey: string;
    previewType: string;
    previewInput: string;
    previewKey: string;
    reStreams: ReStreams[];
}

export interface RoomProps {
    room: ObsConfig;
    password: string;
    muted: boolean;
    zoomEndpoint?: string;
    onRequestMuteState: (muted: boolean) => void;
    musicController: MusicController;
    schedule: Schedule;
}

export interface SceneManagerProps {
    obs: OBS;
    room: ObsConfig;
    currentScene: string;
    previewScene: string;
    technicianStream: string;
    streamTracker?: PanelStreamTracker;
    requestMuteState: (muted: boolean) => void;
    muted: boolean;
}

export interface StreamManagerProps {
    room: ObsConfig;
    activeRoom: string;
    obs: OBS;
    zoomObs?: OBS;
    password: string;
    muted: boolean;
    requestMuteState: (muted: boolean) => void;
    streamTracker?: PanelStreamTracker
    transitionSafe: boolean;
    schedule: Schedule;
}

export interface StreamScheduleProps {
    room: ObsConfig;
    activeRoom: string;
    activeStream?: string;
    password: string;
    muted: boolean;
    requestMuteState: (muted: boolean) => void;
    currentStreamKey: string;
    currentStreamType: string;
    requestSwitchFeed: (type: string, key: string, tag: string) => void;
    streamTracker?: PanelStreamTracker;
    schedule: Schedule;
}

export interface StreamPreviewProps {
    app?: string;
    room: ObsConfig;
    muted: boolean;
    previewKey: string;
}

export interface StreamStatusProps {
    obs: OBS;
    room: ObsConfig;
    muted: boolean;
}

export enum mediaStateEnum {
    ERROR = "ERROR",
    UNKNOWN = "UNKNOWN",
    LOADING = "LOADING...",
    MISSING = "FILE MISSING!",
    PAUSED = "PAUSED",
    STOPPED = "STOPPED",
    PLAYING = "PLAYING",
    ENDED = "ENDED"
}

// Stolen from obs-websocket-js, which has this type but does not export it.
/*interface StreamStatus {
    fps: number;
    streaming: boolean;
    "replay-buffer-active": boolean;
    "bytes-per-sec": number;
    "kbits-per-sec": number;
    strain: number;
    "total-stream-time": number;
    "num-total-frames": number;
    "num-dropped-frames": number;
    recording: boolean;
    "render-total-frames": number;
    "render-missed-frames": number;
    "output-total-frames": number;
    "output-skipped-frames": number;
    "average-frame-time": number;
    "cpu-usage": number;
    "memory-usage": number;
    "free-disk-space": number;
    "preview-only": boolean;
}*/


