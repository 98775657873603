import React, {ReactElement, useEffect, useState} from "react";
import {Grid, Slider} from "@mui/material";
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import {OBS} from "./utils/obs";


interface StreamVolumeProps {
    obs?: OBS;
    source: string;
}

export function StreamVolume(props: StreamVolumeProps): ReactElement {
    const [volume, setVolume] = useState(0.0);
    const [receivedData, setReceivedData] = useState(false);

    useEffect(() => {
        if(!props.obs) {
            return;
        }
        const obs = props.obs;
        (async () => {
            const result = await obs.sendCommand("GetInputVolume", {inputName: props.source});
            setVolume(result.inputVolumeMul);
            setReceivedData(true);
        })();

        let debounce: ReturnType<typeof setTimeout> | null = null;
        const volumeChanged = (data: {inputName: string, inputVolumeMul: number}) => {
            if (data.inputName !== props.source) {
                return;
            }
            clearTimeout(debounce!);
            debounce = setTimeout(() => {
                setVolume(data.inputVolumeMul);
            }, 500);
        };

        obs.obs().on("InputVolumeChanged", volumeChanged);
        return () => {
            obs.obs().off("InputVolumeChanged", volumeChanged);
            clearTimeout(debounce!);
        }
    }, [props.obs, props.source]);

    async function handleChange (event: Event, value: number | number[]) {
        if (typeof value !== "number") {
            console.error("the volume slider should produce exactly one value.");
            return;
        }
        const mul = value * value * value;
        setVolume(mul);
        await props.obs!.sendCommand("SetInputVolume", {inputName: props.source, inputVolumeMul: mul});
    }

    return (
        <Grid container spacing={2}>
            <Grid item>
                <VolumeDown />
            </Grid>
            <Grid item xs>
                <Slider value={Math.cbrt(volume)} onChange={handleChange} valueLabelDisplay="auto" valueLabelFormat={(v) => v === 0 ? '-inf' :  (20*Math.log10(volume)).toFixed(1)} min={0} max={1} step={0.01} disabled={!receivedData} />
            </Grid>
            <Grid item>
                <VolumeUp />
            </Grid>
        </Grid>
    )
}