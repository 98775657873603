import React, {ReactElement, useState} from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {OBS} from "./utils/obs";
import {PanelStreamTracker} from "./utils/panelstreamtracker";
import PlayArrow from "@mui/icons-material/PlayArrow";
import {mediaStateEnum} from "./types";
import {Pause, RestartAlt} from "@mui/icons-material";

interface VideoSettingsProps {
    obs: OBS;
    streamTracker?: PanelStreamTracker
    mediaState: mediaStateEnum;
    transitionSafe: boolean;
    mediaRestartOnTransition: boolean;
}

export function VideoSettings(props: VideoSettingsProps): ReactElement {
    const [anchor, setAnchor] = useState(null as HTMLElement | null);
    const [promptVideoRestart, setVideoRestart] = useState(null as null | {r: (x: boolean) => void});
    const [promptVideoPause, setVideoPause] = useState(null as null | {r: (x: boolean) => void});

    async function toggleRestartOnTransition() {
        if(props.mediaRestartOnTransition) {
            console.log("Disable Restart on Transition")
            await props.obs.enableVideoRestartOnTransition("prerecord", false)
        } else {
            console.log("Enable Restart on Transition")
            await props.obs.enableVideoRestartOnTransition("prerecord", true)
        }
    }

    async function videoPlay() {
        console.log("Playing video")
        await props.obs.playVideo("prerecord")
    }
    async function videoPause() {
        console.log("Pausing video")
        await props.obs.pauseVideo("prerecord")
    }
    function videoPausePrompt(): Promise<boolean> {
        return new Promise<boolean>(((resolve) => {
            setVideoPause({r: resolve});
        }))
    }
    async function requestVideoPause() {
        if (!props.transitionSafe && !(await videoPausePrompt())) {
            return;
        }
        await videoPause()
    }



    async function videoRestart() {
        console.log("Restarting video")
        await props.obs.restartVideo("prerecord")
        await props.obs.pauseVideo("prerecord")
    }
    function videoRestartPrompt(): Promise<boolean> {
        return new Promise<boolean>(((resolve) => {
            setVideoRestart({r: resolve});
        }))
    }
    async function requestVideoRestart() {
        if (!props.transitionSafe && !(await videoRestartPrompt())) {
            return;
        }
        if(props.transitionSafe) {
            await videoRestart()
        } else {
            //We are live so we should restart and play
            await videoRestart()
            await videoPlay()
        }
    }

    return <>
        <IconButton
            title="Panel Settings"
            edge="end"
            onClick={(e) => setAnchor(e.target as HTMLElement)}
            size="large">
            <SettingsIcon />
        </IconButton>
        <Popover open={Boolean(anchor)}
                 onClose={() => setAnchor(null)}
                 anchorEl={anchor}
                 anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'left'}}>
            <List style={{width: 170}}>
                <ListItem>
                    <ListItemText primary="Video Controls" />
                </ListItem>
                <ListItem>
                    {props.mediaState === mediaStateEnum.PLAYING ?
                        <><Button onClick={() => requestVideoPause()} color="secondary" title={"Pause Video"}><Pause /></Button>
                        </> : <>
                        <Button onClick={() => videoPlay()} color="secondary" title={"Play Video"}><PlayArrow /></Button>
                        </>}
                    <Button title="Restart Video" onClick={() => requestVideoRestart()} >
                        <RestartAlt></RestartAlt>
                    </Button>
                </ListItem>
                <ListItem style={{paddingTop: 0}}>
                    <Button variant="contained" onClick={() => toggleRestartOnTransition()} >
                        {props.mediaRestartOnTransition ? <>Disable Restart on Transition</> : <>Enable Restart on Transition</>}
                    </Button>
                </ListItem>
            </List>
        </Popover>
        <Dialog open={!!promptVideoPause} onClose={() => {promptVideoPause!.r(false); setVideoPause(null)}}>
            <DialogTitle>Perform interrupting switch?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are trying to pause a video while a panel is being streamed. This will pause the video for the
                    viewers. Are you sure you want to do this?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {promptVideoPause!.r(false); setVideoPause(null)}} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {promptVideoPause!.r(true); setVideoPause(null)}} color="secondary">
                    Pause video
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={!!promptVideoRestart} onClose={() => {promptVideoRestart!.r(false); setVideoRestart(null)}}>
            <DialogTitle>Perform interrupting switch?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are trying to restart a video while a panel is being streamed. This will restart the video to the beginning
                    and start since it's live.
                    If you are playing a prerecorded video, it will not be possible to resume where you left off.
                    Consider changing away from the main panel scene before switching.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {promptVideoRestart!.r(false); setVideoRestart(null)}} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {promptVideoRestart!.r(true); setVideoRestart(null)}} color="secondary">
                    Restart video
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}