import React, {ReactElement, useEffect, useState} from 'react';
import {SceneManager} from "./scenemanager";
import {StreamManager} from "./streammanager";
import {StreamStatus} from "./streamstatus";
import "./css/room.css";
import {Card, CardContent, CardHeader, Divider, IconButton} from "@mui/material";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import LibraryMusic from '@mui/icons-material/LibraryMusic';
import {PanelStreamTracker} from "./utils/panelstreamtracker";
import {MusicControl} from "./musiccontrol";
import {OBS} from "./utils/obs";
import {PANEL_SCENE} from "./config/constants";
import {RoomProps} from "./types";
import {StreamControl} from "./streamcontrol";
import { RoomSettings } from './roomsettings';

const RECONNECT_DELAY_MS = 10000;

const streamTrackers: {[password: string]: PanelStreamTracker} = {};
async function getStreamTracker(password: string): Promise<PanelStreamTracker> {
    if (!streamTrackers[password]) {
        streamTrackers[password] = new PanelStreamTracker(password);
        await streamTrackers[password].start();
    }
    return streamTrackers[password];
}

export function Room(props: RoomProps): ReactElement {
    const [obs, setObs] = useState(undefined as OBS | undefined);
    const [zoomObs, setZoomObs] = useState(undefined as OBS | undefined);
    const [connected, setConnected] = useState(false);
    const [currentScene, setCurrentScene] = useState("");
    const [previewScene, setPreviewScene] = useState("");

    const [previewAudio, setPreviewAudio] = useState(false);
    const [sceneAudio, setSceneAudio] = useState(false);
    const [streamTracker, setStreamTracker] = useState(undefined as PanelStreamTracker | undefined);
    const [showingMusicControls, setShowingMusicControls] = useState(false);

    const [activeRoom, setActiveRoom] = useState(props.room.name);

    useEffect(() => {
        (async () => {
            setStreamTracker(await getStreamTracker(props.password))
        })();
    }, [props.password]);

    useEffect(() => {
        (async () => {
            if(!streamTracker?.config || !obs) {
                return
            }
            await obs.loadConfig(streamTracker.config)
        })();
    }, [streamTracker, obs]);

    useEffect(() => {
        if (!streamTracker) {
            return;
        }

        const obs = new OBS();

        async function connect() {
            if(connected!) {
                return
            }
            try {
                await obs.connect(props.room.endpoint, props.password)
                setObs(obs);
                setConnected(true);
            } catch (e) {
                console.log("Connection failed", e);
                setConnected(false)
                setTimeout(connect, RECONNECT_DELAY_MS);
                return;
            }

            setConnected(true);
            setCurrentScene(await obs.getCurrentProgramScene())
            setPreviewScene(await obs.getPreviewScene())


            obs.obs().on("CurrentProgramSceneChanged", data => {
                console.log("CurrentProgramSceneChanged", data.sceneName)
                setCurrentScene(data.sceneName);
            })

            obs.obs().on("CurrentPreviewSceneChanged", data => {
                console.log("CurrentPreviewSceneChanged", data.sceneName)
                setPreviewScene(data.sceneName);
            })

            obs.obs().on("ConnectionClosed", onClose);

        }

            function onClose() {
                setConnected(false);
                setObs(undefined);
                console.log("closed, retrying...");
                setTimeout(connect, RECONNECT_DELAY_MS);
            }

            connect().finally();

            return () => {
                obs.obs().off("ConnectionClosed", onClose);
                obs.disconnect().finally();
                setConnected(false);
                setObs(undefined);
            }
    }, [props.room.endpoint, props.password, streamTracker]);

    useEffect(() => {
        if (!props.zoomEndpoint) {
            return;
        }
        const obs = new OBS();

        async function connect() {
            try {
                await obs.connect(props.zoomEndpoint, props.password)
            } catch(e) {
                console.log("Connection failed", e);
                setTimeout(connect, RECONNECT_DELAY_MS);
                return;
            }
            setZoomObs(obs);
            obs.obs().on("ConnectionClosed", onClose);
        }

        function onClose() {
            setZoomObs(undefined);
            console.log("zoom closed, retrying...");
            setTimeout(connect, RECONNECT_DELAY_MS);
        }

        connect().finally();

        return () => {
            obs.obs().off("ConnectionClosed", onClose);
            obs.disconnect().finally();
            setZoomObs(undefined);
        }
    }, [props.zoomEndpoint, props.password]);

    function showMusicPrompt() {
        setShowingMusicControls(true);
    }
    function showSettingsPrompt() {
        setShowingMusicControls(true);
    }

    const connectedUI = obs && (zoomObs || !props.zoomEndpoint) ? <>
        <SceneManager
            obs={obs}
            room={props.room}
            currentScene={currentScene}
            previewScene={previewScene}
            technicianStream={props.room.techStream}
            streamTracker={streamTracker}
            requestMuteState={(muted) => setSceneAudio(!muted)}
            muted={props.muted || !sceneAudio}
        />
        <Divider />
        <StreamManager
            obs={obs}
            room={props.room}
            activeRoom={activeRoom}
            password={props.password}
            muted={props.muted || !previewAudio}
            requestMuteState={(muted) => setPreviewAudio(!muted)}
            streamTracker={streamTracker}
            transitionSafe={currentScene !== PANEL_SCENE}
            schedule={props.schedule}
        />
        <Divider />
        <StreamControl obs={obs} room={props.room} muted={props.muted || previewAudio || sceneAudio} />
        <StreamStatus obs={obs} room={props.room} muted={props.muted || previewAudio || sceneAudio} />
    </> : <>Disconnected.</>;

    const muteButton = <IconButton onClick={() => props.onRequestMuteState(!props.muted)} size="large">{props.muted ? <VolumeOffIcon color="secondary" /> : <VolumeUpIcon color="primary" />}</IconButton>

    return (
        <Card style={{width: 450, margin: 20}}>
            <CardHeader
                title={props.room.name}
                style={{paddingBottom: 0}}
                action={<>
                    <IconButton onClick={() => showMusicPrompt()} color={"primary"} size="large"><LibraryMusic /></IconButton>{muteButton}
                    <RoomSettings activeRoom={activeRoom} onRoomChange={(x: React.SetStateAction<string>) => setActiveRoom(x)}></RoomSettings>
                </>} />
            <CardContent>
                {connectedUI}
            </CardContent>
            <MusicControl obs={obs} musicController={props.musicController} stream={props.room.name} open={showingMusicControls} onClose={() => setShowingMusicControls(false)} />
        </Card>
    );
}
