import React, {ReactElement, useEffect, useState} from 'react';
import {FormControlLabel, IconButton, Popover, Radio, RadioGroup, Typography} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {TECH_SCENE} from "./config/constants";
import {StreamPreview} from "./streampreview";
import {SceneManagerProps} from "./types";

export function SceneManager(props: SceneManagerProps): ReactElement {
    const [sceneList, setSceneList] = useState([] as string[]);
    const [techStreamLive, setTechStreamLive] = useState(false);
    const [previewAnchor, setPreviewAnchor] = useState(null as HTMLElement | null);

    function preview(el:HTMLElement) {
        setPreviewAnchor(el)
        props.requestMuteState(false);
    }

    function unPreview(): void {
        setPreviewAnchor(null);
        props.requestMuteState(true);
    }

    useEffect(() => {
        if (!props.streamTracker) {
            return;
        }

        setTechStreamLive(!!props.streamTracker.mapping?.get(props.technicianStream)?.live);

        const update = () => {
            setTechStreamLive(!!props.streamTracker?.mapping?.get(props.technicianStream)?.live);
        };

        props.streamTracker.addEventListener('streamupdated', update)

        return () => {
            props.streamTracker!.removeEventListener('streamupdated', update);
        }
    }, [props.technicianStream, props.streamTracker]);

    useEffect(() => {
        const updateScenes = async () => {
            const sceneList = await props.obs.sendCommand('GetSceneList');
            let scenes : string[] = sceneList.scenes.map(x => x.sceneName!.toString())
            let scenesShow: string[] = []
            let scenesHidden: string[] = []
            // Remove any scene that's meant to be hidden
            scenes.forEach(x => {
                if(x!.toString()[0] === '_' ) {
                    scenesHidden.push(x)
                } else {
                    scenesShow.push(x)
                }
            })
            // console.debug("Scenes available: ", scenesShow)
            // console.debug("Scenes hidden: ", scenesHidden)

            setSceneList(scenesShow.reverse());
        }
        (async () => {
            await updateScenes();
            props.obs.obs().on("CurrentProgramSceneChanged", updateScenes);
        })();

        return () => {
            props.obs.obs().off("CurrentProgramSceneChanged", updateScenes);
        }
    }, [props.obs]);

    async function setScene(name: string) {
        if(!props.obs) {
            return;
        }
        await props.obs.setPreviewScene(name)
        //await props.obs.sendCommand("TriggerStudioModeTransition")
    }

    return (
        <div className="SceneManager" style={{marginTop: 4, marginBottom: 4, height: 140, overflow: 'auto'}}>
            <RadioGroup title={"Select Preview"} value={props.previewScene} onChange={(e) => setScene(e.target.value)}>
                {sceneList.map(x => <FormControlLabel control={<Radio />} value={x} label={<>
                    {x === props.currentScene ? <Typography color={"gold"} title={"Active Stream"} >{x}</Typography> : <>{x}</>}
                    {x === TECH_SCENE && techStreamLive ? <IconButton
                        title="Preview"
                        edge="end"
                        onClick={(e) => preview(e.target as HTMLElement)}
                        size="large">
                        <VisibilityIcon color="secondary" />
                    </IconButton> : <></>}
                </>} key={x} />)}
            </RadioGroup>
            <Popover
                open={Boolean(previewAnchor)}
                anchorEl={previewAnchor}
                onClose={() => unPreview()}
                anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                transformOrigin={{vertical: 'center', horizontal: 'left'}}
            >
                <StreamPreview room={props.room} muted={props.muted} previewKey={""}/>
            </Popover>
        </div>
    );
}
