import React, {ReactElement, useEffect, useState} from 'react';
import "./css/streamstatus.css";
import {secondsToClock} from "./utils/timeutils";
import {StreamStatusProps} from "./types";

export function StreamStatus(props: StreamStatusProps): ReactElement {
    const [streamFPS, setStreamFPS] = useState(0);
    const [cpuUsage, setCpuUsage] = useState(0);
    const [memUsage, setMemUsage] = useState(0);
    const [availableDiskSpace, setAvailableDiskSpace] = useState(0);
    const [activeFps, setActiveFps] = useState(0);
    const [averageFrameRenderTime, setAverageFrameRenderTime] = useState(0);
    const [renderSkippedFrames, setRenderSkippedFrames] = useState(0);
    const [renderTotalFrames, setRenderTotalFrames] = useState(0);
    const [outputSkippedFrames, setOutputSkippedFrames] = useState(0);
    const [outputTotalFrames, setOutputTotalFrames] = useState(0);
    const [kbitsPerSec, setKbitsPerSec] = useState(0);
    const [totalStreamTime, setTotalStreamTime] = useState(0);
    const [numTotalFrames, setNumTotalFrames] = useState(0);
    const [numDroppedFrames, setNumDroppedFrames] = useState(0);

    useEffect(() => {
            const interval = setInterval(async () => {
                try {
                    const stats = await props.obs.sendCommand("GetStats")
                    if (stats === null) {
                        return
                    }
                    setStreamFPS(stats.activeFps)
                    setCpuUsage(stats.cpuUsage)
                    setMemUsage(stats.memoryUsage)
                    setAvailableDiskSpace(stats.availableDiskSpace)
                    setActiveFps(stats.activeFps)
                    setAverageFrameRenderTime(stats.averageFrameRenderTime)
                    setRenderSkippedFrames(stats.renderSkippedFrames)
                    setRenderTotalFrames(stats.renderTotalFrames)
                    setOutputSkippedFrames(stats.outputSkippedFrames)
                    setOutputTotalFrames(stats.outputTotalFrames)

                    const listOutputs = await props.obs.sendCommand("GetOutputList")
                    let outputName = ""
                    if (listOutputs.outputs[0].outputName === 'simple_file_output') {
                        outputName = "simple_file_output"
                    } else if (listOutputs.outputs[0].outputName === 'adv_stream') {
                        outputName = "adv_stream"
                    } else if (listOutputs.outputs[0].outputName === 'simple_stream') {
                        outputName = "simple_stream"
                    }

                    if (outputName !== "") {
                        const outputInfo = await props.obs.sendCommand("GetOutputStatus", {outputName: outputName})
                        setKbitsPerSec(outputInfo.outputBytes);
                        setTotalStreamTime(outputInfo.outputDuration/1000);
                        setNumTotalFrames(outputInfo.outputTotalFrames);
                        setNumDroppedFrames(outputInfo.outputSkippedFrames);

                    }
                } catch(e) {
                }
            }, 1000);

        return () => {
            clearInterval(interval)
        }
    }, [props.obs]);

    return <div className="StreamStatus">
        <details>
            <summary>Stream status</summary>
            <ul>
                <li>kb/s: {Math.floor(kbitsPerSec / 1000)}</li>
                <li>FPS: {streamFPS.toFixed(4)}</li>
                <li>CPU usage: {cpuUsage ? cpuUsage.toFixed(2) : 0}%</li>
                <li>Stream time: {secondsToClock(totalStreamTime)} </li>
                <li>Dropped
                    frames: {numDroppedFrames} ({(numTotalFrames > 0 ? numDroppedFrames / numTotalFrames * 100 : 0).toFixed(1)}%)
                </li>
                <li>Memory Usage: {Math.floor(memUsage)} MB</li>
                <li>Disk Usage: {Math.floor(availableDiskSpace)} MB Free</li>
                <li>Active Fps: {activeFps.toFixed(4)}</li>
                <li>Average Frame Render Time: {averageFrameRenderTime}</li>
                <li>Render Skipped Frames: {renderSkippedFrames}</li>
                <li>Render Total Frames: {renderTotalFrames}</li>
                <li>Output Skipped Frames: {outputSkippedFrames}</li>
                <li>Output Total Frames: {outputTotalFrames}</li>
            </ul>
        </details>
    </div>;
}
