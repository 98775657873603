import React, {ReactElement, useEffect, useState} from 'react';
import {StreamPreview} from "./streampreview";
import "./css/streamstatus.css";
import {StreamStatusProps} from "./types";
import {
    Alert,
    Button,
    Grid,
    Snackbar,
} from "@mui/material";

export function StreamControl(props: StreamStatusProps): ReactElement {
    const [streaming, setStreaming] = useState(false);
    const [recording, setRecording] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [toastContent, setToastContent] = React.useState("");


    useEffect(() => {
            const interval = setInterval(async () => {
                try {

                    const listOutputs = await props.obs.sendCommand("GetOutputList")

                    setStreaming(listOutputs.outputs[0].outputActive === true)
                    // Recording is the secondary output
                    setRecording(listOutputs.outputs[1].outputActive === true)
                } catch(e) {
                }
            }, 1000);

        function streamStateChanged(data: { outputActive: boolean | ((prevState: boolean) => boolean); }) {
            setStreaming(data.outputActive)
        }

        function recordStateChanged(data: { outputActive: boolean | ((prevState: boolean) => boolean); }) {
            setRecording(data.outputActive)
        }

        props.obs.obs().on("StreamStateChanged", streamStateChanged)
        props.obs.obs().on("RecordStateChanged", recordStateChanged)
        return () => {
            props.obs.obs().off("StreamStateChanged", streamStateChanged)
            props.obs.obs().off("RecordStateChanged", recordStateChanged)
            clearInterval(interval)
        }
    }, [props.obs]);

    if (!props.room) {
        return <></>;
    }

    async function startStream() {
        await props.obs.startStreaming()
    }

    async function stopStream() {
        await props.obs.stopStreaming()
    }

    async function startRecord() {
        await props.obs.startRecording()
    }

    async function stopRecord() {
        await props.obs.stopRecording()
    }

    async function sendRequest(url: string) {
        console.log("Sending Request {}", url)
        const result = await fetch(url);
        console.log("Results: ", result)

        if (result.ok) {
            if (result.ok) {
                setToastContent("Success")
                setOpen(true)
            } else {
                console.error(result)
                setToastContent("Failed, check console")
                setOpen(true)
            }
        }

        return result
    }

    const handleClose = () => {
        setOpen(false);
        setToastContent("");
    };

    const reStreams = props.room.reStreams


    const reStreamList = reStreams.map(x => (
        <>
            <Grid item xs={5}>
                {x.name} ReStream:
            </Grid>
            <Grid item xs={3}>
                <Button color="primary" variant="contained" key={x.name} onClick={() => sendRequest(x.start)}> Enable</Button>
            </Grid>
            <Grid item xs={3}>
                <Button color="primary" variant="contained" key={x.name} onClick={() => sendRequest(x.stop)}> Disable</Button>
            </Grid>
        </>
    ))

    return <div className="StreamControl">
        {streaming ? <>
            <div className="StreamStatus-preview">
                <h4>Live Stream</h4>
                <StreamPreview room={props.room} muted={props.muted} previewKey={""}/>
        </div>
            </> : <></>}
        <details>
            <summary>Stream controls</summary>
            <ul>
                <li>Streaming: {streaming ? "yes" : "no"}</li>
                <li>Recording: {recording ? "yes" : "no"}</li>
            </ul>
            <div className="StreamManager-fixers">
                <Grid container   direction="row"  justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                    OBS Outputs:
                    </Grid>
                    <Grid item xs={3}>
                        {streaming ?
                            <Button color="primary" variant="contained" onClick={stopStream}>Stop stream</Button> :
                            <Button color="primary" variant="contained" onClick={startStream}>Start stream</Button>}
                    </Grid>
                    <Grid item xs={3}>
                        {recording ?
                            <Button color="primary" variant="contained" onClick={stopRecord}>Stop record</Button> :
                            <Button color="primary" variant="contained" onClick={startRecord}>Start record</Button>}
                    </Grid>

                    {reStreamList}
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        >
                            <Alert
                                onClose={handleClose}
                                severity='success'
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                {toastContent}
                            </Alert>
                    </Snackbar>
                </Grid>
            </div>
        </details>
    </div>
;
}
