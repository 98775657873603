//import OBS from "@ponyfest/obs-websocket-js";

// Toggle this to set the stream controller mode
// Can only be one type (RTMP or SRT)
export let DEFAULT_PANEL_TYPE = "webrtc"
export let DEFAULT_PANEL_MODE = "WEBRTC"
export let DEFAULT_PANEL_GAIN = "Gain"
export let DEFAULT_PANEL_COMPRESSOR = "Compressor"

export const TIMEZONE = "America/New_York";
//export const PANEL_SOURCE = "SRT";
export const PANEL_SCENE = "Panel";
//export const PREREC_SOURCE = "Prerecord";
//export const PANEL_AUDIO_COMPRESSOR = "Compressor";
//export const PANEL_AUDIO_GAIN = "Gain";
export const WATERMARK_SOURCE = "Logo";
export const VISUALIZER_SOURCE = "AudioVisualizer";
export const VISUALIZER_TAG = "Nametag";
export const VISUALIZER_TAGBG = "NametagBg";
export const BACKGROUND_SOURCE = "Dead";

//export const PANEL_SCENE = "Panel";
export const TITLE_MUSIC_SOURCE = "PFO Music";

export const TECH_SCENE = "Technician Stream";
export const TECH_SOURCE = "Tech_Src";

export const STREAM_TRACKER = "https://tracker.stream-control.ponyfest.horse";
//export const STREAM_TRACKER = "http://127.0.0.1:8082";
export const SCHEDULE_API = "https://schedule-api.ponyfest.horse/schedule";
export const MUSIC_CONTROL = "https://music-api.stream-control.ponyfest.horse";

export const OBS_PATH = "C:/obs"
//export const PRERECORD_PATH = OBS_PATH + "Prerecs/"

// Default is SRT
export let PREVIEW_URL = "wss://stream3.brony.eu:3334/pony/";
export let PREVIEW_POST_URL = "?transport=tcp";
// This is used to assemble the SRT intake for OBS
//export let OUTPUT_URL = "https://stream.brony.eu/event/fullscreen.html?app=pony&type=webrtc&station=";
//export let PREVIEW_APP = ""

/*
if ( PANEL_MODE === "RTMP" ) {
    PREVIEW_URL = "wss://previews.stream-control.ponyfest.horse/stream/"
    OUTPUT_URL = "rtmp://rtmp.ponyfest.horse/live/"
    //PREVIEW_APP = "live"
}*/
