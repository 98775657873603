
export function secondsToClock(seconds: number) {
    const hour = Math.floor(seconds/3600).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
    const minute = Math.floor((seconds%3600)/60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
    const second = Math.floor(seconds%60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })

    let clock = ""

    return clock.concat(hour,":",minute,":",second)
}
