import React, {ReactElement, useEffect, useRef, useState} from 'react';
import './css/streampreview.css';
import OvenPlayer, {OvenPlayerSource} from "ovenplayer";
import {StreamPreviewProps} from "./types";

export function StreamPreview(props: StreamPreviewProps): ReactElement {
    const canvasRef = useRef(null as HTMLCanvasElement | null);
    const levelsRef = useRef(null as HTMLCanvasElement | null);
    const playerRef = useRef(null as any);
    const [playerKey, setPlayerKey] = useState(props.room.previewKey)

    useEffect(() => {
        if (canvasRef.current === null) {
            return;
        }
        if(props.previewKey === "") {
            setPlayerKey(props.room.previewKey)
        } else {
            setPlayerKey(props.previewKey)
        }

        /*
        // This has a bunch of machinery for [a dealing with multiple channels, and b] dealing with averaging
        // over multiple audio packets. In fact, we use a single packet and our preview audio is mono anyway,
        // so none of it is currently used.
        // It lives inside this component mostly for performance reasons, since we need to do all this every
        // time we received audio data, which is quite often.
        const levels = [0];
        let chunkPointer = 0;
        const oldChunks = [[0, 0, 0, 0, 0, 0, 0, 0]];
        const peaks = [-Infinity];
        const lastPeakReset = [0, 0];
        function updateMeters(channels: Float32Array[]) {
            for (let i = 0; i < channels.length && i < levels.length; i++) {
                const level = channels[i].reduce((p, c) => p + Math.pow(c, 2))
                oldChunks[i][chunkPointer] = level;
                levels[i] = 20 * Math.log10(Math.sqrt(oldChunks[i].reduce((a, b) => a + b) / channels[i].length * oldChunks[i].length));
                if (levels[i] > peaks[i] || Date.now() > lastPeakReset[i] + 5000) {
                    peaks[i] = levels[i];
                    lastPeakReset[i] = Date.now();
                }
            }
            chunkPointer = (chunkPointer + 1) % oldChunks[0].length;

            const levelsCanvas = levelsRef.current!;

            const context = levelsCanvas.getContext('2d')!;

            context.fillStyle = '#f52757';
            context.clearRect(0, 0, levelsCanvas.width, levelsCanvas.height);
            context.fillRect(0, 0, levelsCanvas.width * ((levels[0] - SCALE_BOTTOM) / (SCALE_TOP - SCALE_BOTTOM)), levelsCanvas.height);
            const peakPos = levelsCanvas.width * ((peaks[0] - SCALE_BOTTOM) / (SCALE_TOP - SCALE_BOTTOM))
            context.fillRect(peakPos, 0, 2, levelsCanvas.height);
        }*/

        //const newScript = document.createElement("script");
        //const debug: boolean = OvenPlayer.debug(true);

        let app = 'pony';
        //const server = 'stream3.brony.eu';
        const server = 'stream12.brony.eu';
        //const delay = 0.6;
        const sources: OvenPlayerSource[] = [{
            type: "webrtc",
            file: 'wss://' + server + ':3334/' + app + '/' + playerKey + '-smol',
            label: 'UDP (2 sec latency) Smol'
        },{
            type: "webrtc",
            file: 'wss://' + server + ':3334/' + app + '/' + playerKey,
            label: 'UDP (2 sec latency)'
        },{
            type: "webrtc",
            file: 'wss://' + server + ':3334/' + app + '/' + playerKey + '-smol?transport=tcp',
            label: 'TCP (3 sec latency) Smol'
        },{
            type: "webrtc",
            file: 'wss://' + server + ':3334/' + app + '/' + playerKey + '?transport=tcp',
            label: 'TCP (3 sec latency)'
        }]


        playerRef.current = OvenPlayer.create(canvasRef.current?.id, {
            loop: true,
            autoStart: true,
            mute: props.muted,
            title: playerKey,
            expandFullScreenUI: false,
            hidePlaylistIcon: true,
            showSeekControl: false,
            controls: true,
            sources: sources,
            webrtcConfig: {
                playoutDelayHint: 1.10
            },
        });
        console.log("Started player: ", playerRef.current)

        //loadingRetryCount: 30,
        /*sources: [],*/
        //lowLatencyMpdLiveDelay: 2,

        /*            player.on("error", function(error) {
                        console.log('Playback error: ' + error.message + ' - ' + error.reason);
                    });*/

        // Hijack the audio output to collect its audio data for our level meter
        /*        let p = playerRef.current.audioOut.play.bind(playerRef.current.audioOut);
                playerRef.current.audioOut.play = (sampleRate: number, left: Float32Array, right: Float32Array) => {
                    updateMeters([left]);
                    p(sampleRate, left, right);
                }*/

        return () => {
            /*if (playerRef.current !== null) {
                playerRef.current.remove();
                playerRef.current = null;
            }*/
        }
    }, [props.app, playerKey, props.previewKey, props.muted]);

    useEffect(() => {
        if (!playerRef.current) {
            console.warn(`Trying to set mute status for ${playerKey}, but failed because the player does not exist.`)
            return;
        }
        playerRef.current.setMute(props.muted);
    }, [props.room.previewKey, props.previewKey, props.muted]);

    return <div className="SRTPreview">
        <canvas className="SRTPreview-video" id={playerKey} ref={canvasRef}/>
        <canvas className="SRTPreview-level" id="SRTPreview-level" ref={levelsRef} style={{width: "100%"}} height="4"/>
    </div>;
}

document.addEventListener("DOMContentLoaded", () => {
})


document.addEventListener("DOMContentLoaded", () => {
    if (!(window as any).StreamPrev) {
        const newScript = document.createElement("script");
        newScript.id = 'stream_preview';
        document.head.appendChild(newScript);
        console.log("loading Stream Preview");
        //const playerContainer1 = document.createElement('div');

        //document.body.appendChild(playerContainer1);
    }
})