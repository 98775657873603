import React, {ReactElement, useState} from "react";
import {
    Button, FormControl,
    IconButton, InputLabel,
    List,
    ListItem,
    ListItemText, MenuItem,
    Popover, Select, SelectChangeEvent,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
interface RoomSettingsProps {
    activeRoom: string
    onRoomChange: any
}

export function RoomSettings(props: RoomSettingsProps): ReactElement {
    const [anchor, setAnchor] = useState(null as HTMLElement | null)

    return <>
        <IconButton
            title="Room Settings"
            edge="end"
            onClick={(e) => setAnchor(e.target as HTMLElement)}
            size="large"
            color={"primary"}>
            <SettingsIcon />
        </IconButton>
        <Popover open={Boolean(anchor)}
                 onClose={() => setAnchor(null)}
                 anchorEl={anchor}
                 anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'right'}}>
            <List>
                <ListItem>
                    <ListItemText primary="Room Settings" />
                </ListItem>
                <ListItem>
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="active-room">Active Room:</InputLabel>
                        <Select
                            labelId="active-room"
                            id="active-room-select"
                            value={props.activeRoom}
                            label="Room"
                            onChange={(event) => props.onRoomChange?.(event.target.value)}
                        >
                            <MenuItem value={"Aerial Soundwaves Stage"}>Aerial Soundwaves Stage</MenuItem>
                            <MenuItem value={"Bit Rate Stage"}>Bit Rate Stage</MenuItem>
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem style={{paddingTop: 0}}>
                </ListItem>
            </List>
        </Popover>
    </>;
}