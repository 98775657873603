import React, {ReactElement, useEffect, useState} from "react";
import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    Slider,
    Switch,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {
    PANEL_SCENE,
    WATERMARK_SOURCE,
    VISUALIZER_SOURCE,
    DEFAULT_PANEL_TYPE, DEFAULT_PANEL_MODE, DEFAULT_PANEL_GAIN, DEFAULT_PANEL_COMPRESSOR, BACKGROUND_SOURCE
} from "./config/constants";
import {OBS} from "./utils/obs";
import {PanelStreamTracker} from "./utils/panelstreamtracker";

interface PanelSettingsProps {
    obs: OBS;
    streamTracker?: PanelStreamTracker
    currentStreamType: string;
}

export function PanelSettings(props: PanelSettingsProps): ReactElement {
    const [anchor, setAnchor] = useState(null as HTMLElement | null);
    const [watermarkEnabled, setWatermarkEnabled] = useState(false);
    const [watermarkSceneID, setWatermarkSceneID] = useState(0);

    const [visualizerEnabled, setVisualizerEnabled] = useState(false);
    const [visualizerSceneID, setVisualizerSceneID] = useState(0);

    const [backgroundEnabled, setBackgroundEnabled] = useState(false);
    const [backgroundSceneID, setBackgroundSceneID] = useState(0);

    const [compressorEnabled, setCompressorEnabled] = useState(false);
    const [extraGain, setExtraGain] = useState(0);

    const [streamType, setStreamType] = useState(DEFAULT_PANEL_TYPE);
    const [streamSourceName, setStreamSourceName] = useState(DEFAULT_PANEL_MODE);
    const [streamCompressorName, setStreamCompressorName] = useState(DEFAULT_PANEL_COMPRESSOR);
    const [streamGainName, setStreamGainName] = useState(DEFAULT_PANEL_GAIN);


    useEffect(() => {
        (async () => {
            if (!props.streamTracker) {
                return;
            }
            const typeName = props.streamTracker.config?.get(props.currentStreamType)?.type
            if(!typeName) {
                return
            }
            const sourceName = props.streamTracker.config?.get(props.currentStreamType)?.source
            if(!sourceName) {
                return
            }
            const compressorName = props.streamTracker.config?.get(props.currentStreamType)?.compressor
            if(!compressorName) {
                return
            }
            const gainName = props.streamTracker.config?.get(props.currentStreamType)?.gain
            if(!gainName) {
                return
            }

            if(props.currentStreamType === streamType) {
                return
            }

            setStreamType(typeName)
            setStreamSourceName(sourceName)
            setStreamCompressorName(compressorName)
            setStreamGainName(gainName)
        })();
    }, [props.streamTracker, props.currentStreamType, streamType]);

    useEffect(() => {
        (async () => {

            const filterStatus = await props.obs.sendCommand("GetSourceFilter", {sourceName: streamSourceName, filterName: streamCompressorName});
            setCompressorEnabled(filterStatus.filterEnabled);
        })();

        function onSourceFilterVisibilityChanged(data: {filterEnabled: boolean, filterName: string, sourceName: string}) {
            if (data.sourceName === streamSourceName && data.filterName === streamCompressorName) {
                setCompressorEnabled(data.filterEnabled);
            }
        }

        props.obs.obs().on("SourceFilterEnableStateChanged", onSourceFilterVisibilityChanged);

        return () => {
            props.obs.obs().off("SourceFilterEnableStateChanged", onSourceFilterVisibilityChanged);
        }
    }, [props.obs, props.streamTracker, props.currentStreamType, streamCompressorName, streamSourceName]);

    useEffect(() => {
        (async () => {
            const watermarkID = await props.obs.sendCommand("GetSceneItemId", {sceneName: PANEL_SCENE,sourceName: WATERMARK_SOURCE })
            setWatermarkSceneID(watermarkID.sceneItemId)
            const watermarkStatus = await props.obs.sendCommand("GetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: watermarkID.sceneItemId})
            setWatermarkEnabled(watermarkStatus.sceneItemEnabled);
        })();

        function onSceneItemVisibilityChanged(data: {'sceneItemEnabled': boolean, 'sceneItemId': number, 'sceneName': string} ) {
            if (data.sceneItemId === watermarkSceneID && data.sceneName === PANEL_SCENE) {
                setWatermarkEnabled(data.sceneItemEnabled);
            }
        }

        props.obs.obs().on("SceneItemEnableStateChanged", onSceneItemVisibilityChanged);

        return () => {
            props.obs.obs().off("SceneItemEnableStateChanged", onSceneItemVisibilityChanged);
        }
    }, [props.obs, props.streamTracker, props.currentStreamType, watermarkSceneID]);

    useEffect(() => {
        (async () => {
            const visualizerID = await props.obs.sendCommand("GetSceneItemId", {sceneName: PANEL_SCENE,sourceName: VISUALIZER_SOURCE })
            setVisualizerSceneID(visualizerID.sceneItemId)
            const visualizerStatus = await props.obs.sendCommand("GetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: visualizerID.sceneItemId})
            setVisualizerEnabled(visualizerStatus.sceneItemEnabled);
        })();

        function onSceneItemVisibilityChanged(data: {'sceneItemEnabled': boolean, 'sceneItemId': number, 'sceneName': string} ) {
            if (data.sceneItemId === visualizerSceneID && data.sceneName === PANEL_SCENE) {
                setVisualizerEnabled(data.sceneItemEnabled);
            }
        }

        props.obs.obs().on("SceneItemEnableStateChanged", onSceneItemVisibilityChanged);

        return () => {
            props.obs.obs().off("SceneItemEnableStateChanged", onSceneItemVisibilityChanged);
        }
    }, [props.obs, props.streamTracker, props.currentStreamType, visualizerSceneID]);

    useEffect(() => {
        (async () => {
            const backgroundID = await props.obs.sendCommand("GetSceneItemId", {sceneName: PANEL_SCENE,sourceName: BACKGROUND_SOURCE })
            setBackgroundSceneID(backgroundID.sceneItemId)
            const backgroundStatus = await props.obs.sendCommand("GetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: backgroundID.sceneItemId})
            setBackgroundEnabled(backgroundStatus.sceneItemEnabled);
        })();

        function onSceneItemVisibilityChanged(data: {'sceneItemEnabled': boolean, 'sceneItemId': number, 'sceneName': string} ) {
            if (data.sceneItemId === backgroundSceneID && data.sceneName === PANEL_SCENE) {
                setBackgroundEnabled(data.sceneItemEnabled);
            }
        }

        props.obs.obs().on("SceneItemEnableStateChanged", onSceneItemVisibilityChanged);

        return () => {
            props.obs.obs().off("SceneItemEnableStateChanged", onSceneItemVisibilityChanged);
        }
    }, [props.obs, props.streamTracker, props.currentStreamType, backgroundSceneID]);

    useEffect(() => {
        async function updateGain() {
            const gainFilter = await props.obs.sendCommand("GetSourceFilter", {sourceName: streamSourceName, filterName: streamGainName})
            setExtraGain((gainFilter.filterSettings as any).db)
        }
        updateGain().finally()
        let interval = setInterval(updateGain, 10000);

        return () => {
            clearInterval(interval);
        }
    }, [props.obs, props.streamTracker, props.currentStreamType, streamGainName, streamSourceName]);

    async function setWatermark(enabled: boolean) {
        await props.obs.sendCommand("SetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: watermarkSceneID, sceneItemEnabled:enabled})
    }

    async function setVisualizer(enabled: boolean) {
        await props.obs.sendCommand("SetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: visualizerSceneID, sceneItemEnabled:enabled})
    }

    async function setBackground(enabled: boolean) {
        await props.obs.sendCommand("SetSceneItemEnabled", {sceneName: PANEL_SCENE, sceneItemId: backgroundSceneID, sceneItemEnabled:enabled})
    }

    async function setCompressor(enabled: boolean) {
        await props.obs.sendCommand("SetSourceFilterEnabled", {sourceName: streamSourceName, filterName: streamCompressorName, filterEnabled: enabled});
    }

    async function handleGainChange(gain: number) {
        setExtraGain(gain);
        await props.obs.sendCommand("SetSourceFilterSettings", {sourceName: streamSourceName, filterName: streamGainName, filterSettings: {db: gain}});
    }

    return <>
        <IconButton
            title="Panel Settings"
            edge="end"
            onClick={(e) => setAnchor(e.target as HTMLElement)}
            size="large">
            <SettingsIcon />
        </IconButton>
        <Popover open={Boolean(anchor)}
                 onClose={() => setAnchor(null)}
                 anchorEl={anchor}
                 anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'left'}}>
            <List style={{width: 300}}>
                <ListItem>
                    <ListItemText primary="PonyFest watermark" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" checked={watermarkEnabled} onChange={(e, checked) => setWatermark(checked)} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Visualizer Overlay" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" checked={visualizerEnabled} onChange={(e, checked) => setVisualizer(checked)} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Background Image" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" checked={backgroundEnabled} onChange={(e, checked) => setBackground(checked)} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Audio compressor" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" checked={compressorEnabled}  onChange={(e, checked) => setCompressor(checked)} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Extra gain" style={{whiteSpace: "nowrap"}} />
                    <Slider value={extraGain} min={0} max={10} step={0.1} onChange={(e, value) => handleGainChange(value as number)} style={{width: 180}} valueLabelDisplay="auto" valueLabelFormat={(v) => v.toFixed(1)} />
                </ListItem>
                <ListItem style={{paddingTop: 0}}>
                </ListItem>
            </List>
        </Popover>
    </>;
}