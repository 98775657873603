import React, {ReactElement, useEffect, useState} from 'react';
import "./css/streamstatus.css";
import {OBS} from "./utils/obs";
import {PanelStreamTracker} from "./utils/panelstreamtracker";
import {Grid, ListItemText, Slider} from "@mui/material";
import {secondsToClock} from "./utils/timeutils";
import {mediaStateEnum} from "./types";
import {VideoSettings} from "./videosettings";

interface StreamVideoControl {
    obs: OBS
    inputName: string
    streamTracker?: PanelStreamTracker
    transitionSafe: boolean;
}

export function StreamVideo(props: StreamVideoControl): ReactElement {
    const [mediaState, setMediaState] = useState(mediaStateEnum.UNKNOWN);
    const [mediaName, setMediaName] = useState("");

    const [mediaCursor, setMediaCursor] = useState(0);
    const [mediaDuration, setMediaDuration] = useState(0);
    const [mediaBarPercent, setMediaBarPercent] = useState(0);
    const [mediaRestartOnTransition, setMediaRestartOnTransition] = useState(false);

    useEffect(() => {
        if(!props.obs) {
            return;
        }
        const interval = setInterval(async () => {
            try {
                const inputSettings = await props.obs.sendCommand("GetInputSettings", {inputName: props.inputName })
                const inputStatus = await props.obs.sendCommand("GetMediaInputStatus", {inputName: props.inputName })

                if (inputStatus || inputSettings) {
                    if (inputStatus.mediaDuration === null) {
                        setMediaState(mediaStateEnum.MISSING);
                    } else if (inputStatus.mediaDuration === 0) {
                        setMediaState(mediaStateEnum.MISSING);
                    } else {
                        switch (inputStatus.mediaState) {
                            case "OBS_MEDIA_STATE_NONE ":
                                setMediaState(mediaStateEnum.MISSING);
                                break
                            case "OBS_MEDIA_STATE_PLAYING":
                                setMediaState(mediaStateEnum.PLAYING)
                                break
                            case "OBS_MEDIA_STATE_OPENING":
                            case "OBS_MEDIA_STATE_BUFFERING":
                                setMediaState(mediaStateEnum.LOADING)
                                break
                            case "OBS_MEDIA_STATE_PAUSED":
                                setMediaState(mediaStateEnum.PAUSED)
                                break
                            case "OBS_MEDIA_STATE_STOPPED":
                                setMediaState(mediaStateEnum.STOPPED)
                                break
                            case "OBS_MEDIA_STATE_ENDED ":
                                setMediaState(mediaStateEnum.ENDED)
                                break
                            default:
                                setMediaState(mediaStateEnum.ERROR)
                                break
                        }

                        setMediaCursor(Math.round(inputStatus.mediaCursor / 1000))
                        setMediaDuration(Math.round(inputStatus.mediaDuration / 1000))

                        setMediaBarPercent((inputStatus.mediaCursor/inputStatus.mediaDuration) * 100)

                        if (inputSettings.inputSettings.local_file) {
                            const inputPath = inputSettings.inputSettings.local_file.toString()
                            const filename = inputPath.replace(/^.*[\\/]/, '')
                            setMediaName(filename)
                        }
                    }

                    if(inputSettings.inputSettings.restart_on_activate === true) {
                        setMediaRestartOnTransition(true)
                    } else {
                        setMediaRestartOnTransition(false)
                    }

                }

            } catch(e) {
                setMediaState(mediaStateEnum.ERROR)
                setMediaCursor(0)
                setMediaDuration(0)
            }
        }, 250);

        return () => {
            return clearInterval(interval);
        }
    }, [props.obs, props.inputName]);

    useEffect(() => {
        if(!props.obs) {
            return;
        }

        const streamChanged = (data: {inputName: string}) => {
            if (data.inputName !== props.inputName) {
                return;
            }

            console.log("Stream changed")
            //const videoLen = await obs.sendCommand("GetMediaInputStatus", {inputName: props.inputName})
        };

        props.obs.obs().on("MediaInputPlaybackStarted", streamChanged);
        props.obs.obs().on("MediaInputActionTriggered", streamChanged);
        props.obs.obs().on("MediaInputPlaybackEnded", streamChanged);
        //props.obs.obs().on("SceneTransitionEnded", streamChanged);
        return () => {
            props.obs.obs().off("MediaInputPlaybackStarted", streamChanged);
            props.obs.obs().off("MediaInputActionTriggered", streamChanged);
            props.obs.obs().off("MediaInputPlaybackEnded", streamChanged);
            //props.obs.obs().off("SceneTransitionEnded", streamChanged);
        }
    }, [props.obs, props.inputName]);

    function checkVideoStatus(val?: mediaStateEnum | undefined): any {
        if (val) {
            switch (val) {
                case mediaStateEnum.PLAYING:
                    return {color: "green"}
                case mediaStateEnum.LOADING:
                case mediaStateEnum.PAUSED:
                case mediaStateEnum.STOPPED:
                case mediaStateEnum.ENDED:
                    return {color: "orange"}
                //case mediaStateEnum.MISSING:
                //case mediaStateEnum.ERROR:
                default:
                    return {color: "red"}
            }
        } else {
            // This only appears if we have a missing key to prevent accident switching to something that doesn't exist
            return {color: "red"}
        }
    }

// {videoPlaying ? <PlayArrow /> : <PauseIcon  />}
    return (
        <>
        <Grid container spacing={2}>
            <Grid item>
                <ListItemText
                    primaryTypographyProps={checkVideoStatus(mediaState)}
                    primary={mediaState}
                />
            </Grid>
            <Grid item xs>
                <ListItemText
                    primary={<Slider value={mediaBarPercent} valueLabelDisplay="off" />}
                />
            </Grid>
            <VideoSettings obs={props.obs} mediaState={mediaState} streamTracker={props.streamTracker} mediaRestartOnTransition={mediaRestartOnTransition} transitionSafe={props.transitionSafe} />
        </Grid>
        <Grid container spacing={2}>
            <Grid item>
                <ListItemText
                    primaryTypographyProps={{color: "primary", maxWidth: 100}} /*, overflow: "scroll"}}*/
                    primary={mediaName}
                />
            </Grid>
            <Grid item xs>
                <ListItemText
                    primaryTypographyProps={{color: "primary", align: "right"}}
                    primary={<>{secondsToClock(mediaCursor)} / {secondsToClock(mediaDuration)}</>}
                />
            </Grid>
        </Grid>
        </>
    )
}
