import React, {ReactElement, useEffect, useState} from "react";
import {Event} from "./utils/schedule";
import {
    IconButton,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    Snackbar, Tooltip
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinkIcon from '@mui/icons-material/Link';
import {Stream} from "./types";
import {StreamPreview} from "./streampreview";
import ZoomIcon from "./images/zoom_icon.png";
import {TIMEZONE} from "./config/constants";
import {FiberDvr} from "@mui/icons-material";
import WebhookIcon from '@mui/icons-material/Webhook';
import CastIcon from '@mui/icons-material/Cast';
import StreamIcon from '@mui/icons-material/Stream';
import {StreamScheduleProps} from "./types";
import moment, {Moment} from "moment-timezone";



export function StreamSchedule(props: StreamScheduleProps): ReactElement {
    const [previewAnchor, setPreviewAnchor] = useState(null as HTMLElement | null);
    const [streams, setStreams] = useState(null as Map<string, Stream> | null);
    const [notification, setNotification] = useState("");
    const [previewKey, setPreviewKey] = useState("");


    useEffect(() => {
        if (props.streamTracker) {
            if (props.streamTracker.ready) {
                setStreams(new Map<string, Stream>(props.streamTracker.mapping!))

                const update = () => {
                    setStreams(new Map<string, Stream>(props.streamTracker!.mapping));
                };
                //let curr = getActiveSource();
                props.streamTracker.addEventListener('streamupdated', update)

                return () => {
                    props.streamTracker!.removeEventListener('streamupdated', update);
                }
            }
        }
    }, [props.streamTracker])

    if (!props.schedule) {
        return <></>;
    }
    let events = [] as (Event & {stream?: Stream})[];
    if (props.schedule && streams) {
        if (props.schedule.rooms[props.activeRoom]) {
            events = props.schedule.rooms[props.activeRoom].map(x => ({...x, stream: streams.get(x.id)}));
        }
    }

    function preview(el:HTMLElement, key: string) {
        if (key !== "") {
            setPreviewKey(key)
            setPreviewAnchor(el)
            props.requestMuteState(false);

        }
    }

    function unPreview(): void {
        // setPreviewKey("");
        setPreviewAnchor(null);
        props.requestMuteState(true);
    }

    function checkKeyExist(event: any): any {
        if (event?.stream?.key) {
            return checkTimeIntersect(event.startTime,event.endTime)
        } else {
            // This only appears if we have a missing key to prevent accident switching to something that doesn't exist
            return {color: "red"}
        }
    }

    function checkTimeIntersect(startTime: Moment, stopTime: Moment): any {
        const currentTime = moment()
        if(( currentTime.unix() >= startTime.unix() ) && ( currentTime.unix() < stopTime.unix() )) {
            return {color: "gold"}
        } else {
            // This only appears if we have a missing key to prevent accident switching to something that doesn't exist
            return {color: "primary"}
        }
    }

    function getIcon(type?: string | undefined): any {
        if(type) {
            if (type === 'zoom') {
                return <Tooltip title="Zoom"><img alt="Zoom" style={{width: 18, height: 18, verticalAlign: "top", paddingTop: 2}} src={ZoomIcon}/></Tooltip>
            }
            if (type === 'prerecord') {
                return <Tooltip title="PreRecording"><FiberDvr style={{width: 18, height: 18, verticalAlign: "top", paddingTop: 2}}/></Tooltip>
            }
            if (type === 'webrtc') {
                return <Tooltip title="WebRTC"><WebhookIcon style={{width: 18, height: 18, verticalAlign: "top", paddingTop: 2}} /></Tooltip>
            }
            if (type === 'srt') {
                return <Tooltip title="SRT"><CastIcon style={{width: 18, height: 18, verticalAlign: "top", paddingTop: 2}} /></Tooltip>
            }
        }
        return <></>
    }

    function getStreamURL(streamType?: string | undefined, key?: string): any {
        if(streamType) {
            if(props.streamTracker?.config?.get(streamType)) {
                const output = props.streamTracker.config.get(streamType)?.output || ""

                const post = props.streamTracker.config?.get(streamType)?.outputPost || ""

                const link = output + key + post
                console.log("Copied link - ", link)
                return link
            }
            return key
        }
        return <></>
        }

    function getPreviewURL(streamType?: string | undefined, key?: string): any {
        if(streamType) {
            if(props.streamTracker?.config?.get(streamType)) {
                let preview = props.streamTracker.config.get(streamType)?.preview || ""
                let post = props.streamTracker.config?.get(streamType)?.previewPost || ""

                if(streamType === "webrtc") {
                    // We need the secondary preview
                    preview = props.streamTracker.config.get(streamType)?.preview2 || ""
                    post = ""
                }

                const link = preview + key + post
                console.log("Copied link - ", link)
                return link
            }
            return key
        }
        return <></>
    }

    //{checkKeyExist(x.stream)

    const scheduleList = events.map(x => (
        <ListItemButton key={x.id} selected={ ((x.stream?.key === props.currentStreamKey) && (props.currentStreamKey !== ""))} onClick={() => props.requestSwitchFeed(x.stream!.type, x.stream!.key, x.title)}>
            <ListItemText
                primaryTypographyProps={checkKeyExist(x)}
                primary={<><strong>{x.startTime.tz(TIMEZONE).format("ddd HH:mm")}-{x.endTime.tz(TIMEZONE).format("HH:mm")}</strong>: {x.title}</>}
                secondary={<>{getIcon(x.stream?.type)} [{x.id}] {x.panelists}</>}
            />
            <ListItemSecondaryAction>
                <Tooltip title="Copy Input Link">
                <IconButton
                    color={"primary"}
                    edge="end"
                    onClick={(e) => {
                        const streamURL = getStreamURL(x.stream?.type, x.stream?.key)
                        navigator.clipboard.writeText(`${streamURL}`).then(() => {
                            setNotification("Copied " + x.stream?.type + " link to clipboard");
                        }).catch(() => {
                            setNotification("Could not copy link. Key: " + x.stream?.key);
                        });
                        e.stopPropagation();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    size="large">
                    <StreamIcon />
                </IconButton>
                </Tooltip>
                <IconButton
                    title="Copy Preview Link"
                    color={"primary"}
                    edge="end"
                    onClick={(e) => {
                        const previewURL = getPreviewURL(x.stream?.type, x.stream?.key)
                        navigator.clipboard.writeText(`${previewURL}`).then(() => {
                            setNotification("Copied " + x.stream?.type + " link to clipboard");
                        }).catch(() => {
                            setNotification("Could not copy link. Key: " + x.stream?.key);
                        });
                        e.stopPropagation();
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    size="large">
                    <LinkIcon />
                </IconButton>
                {(x.stream?.live || x.stream?.type === "webrtc") ?
                    <IconButton
                        title="Live Preview"
                        edge="end"
                        onClick={(e) => {
                            preview(e.target as HTMLElement, x.stream?.key || "")
                            e.stopPropagation();
                        }}
                        onMouseDown={(e) => e.stopPropagation()}>
                        <VisibilityIcon color="secondary" />
                    </IconButton>
                    : <></>}
            </ListItemSecondaryAction>
        </ListItemButton>
    ))

    return (
        <>
                {
                    events.length === 0 ? <>
                    <List >
                        <ListItemText primaryTypographyProps={{color: 'red'}}>
                            Please select a room in settings (Top right)
                        </ListItemText>
                    </List>
                    </> : <>
                    <List disablePadding={true} style={{marginTop: 8, marginBottom: 8, height: 150, overflow: 'auto'}}>
                            {scheduleList}
                        </List>
                    </>
                }
            <Popover
                open={Boolean(previewAnchor)}
                anchorEl={previewAnchor}
                onClose={() => unPreview()}
                anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                transformOrigin={{vertical: 'center', horizontal: 'left'}}
            >
                <StreamPreview room={props.room} muted={props.muted} previewKey={previewKey}/>
            </Popover>
            <Snackbar open={notification !== ""} autoHideDuration={6000} onClose={() => setNotification("")} message={notification} />
        </>
    );
}
