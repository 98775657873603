// OBS Settings

export const STREAM_TRACKER = "https://tracker.stream-control.ponyfest.horse";
export const SCHEDULE_API = "https://schedule-api.ponyfest.horse/schedule";
export const MUSIC_CONTROL = "https://music-api.stream-control.ponyfest.horse";

// In OBS, we have the Scene Collection organized as followed
// [-: Scene, *:Source, () Filter]
//
// - PFO Logo
//   * PFO Music (Audio Filter: Gain, Compressor, Effect Filters: Scroll)
//   * Splash Screen
//
// - Panel
//   * Logo
//     * White Logo
//     * Black Logo
//   * Video [Group]
//     * SRT Browser [Browser] (Audio Filter: Gain, Compressor)
//     * SRT [Media Source] (Audio Filter: Gain, Compressor)
//     * PreRecord [Media Source] (Audio Filter: Gain, Compressor)
//     * RTMP [Media Source] (Audio Filter: Gain, Compressor)
//   * Tech Backdrop (standbypleasehold.png), should never appear unless one of the top fours disappears
//
// - Title Card
//   * up-next
//   * PFO Music
//   * Overlay
//   * AD Display
//
// - Technical Stream
//   * Manual set to a known good connection
// - Technical Difficulties

// - Countdown

export const MUSIC_SOURCE = "PFO Music";

export const PANEL_SCENE = "Panel"
export const TITLE_CARD = "Title Card"
export const PANEL_VIDEO_GROUP = "Panel Videos"

export const DEFAULT_STREAM_TYPE = "webrtc"

interface OBSConfigMap {
    [key: string]: {
        inputType: string,
        compressor: string,
        gain : string,
        source : string,
        path : string,
        preview : string,
        preview_post? : string,
    }}

export const OBS_CONFIG:OBSConfigMap = {
    'webrtc': {
        'inputType' : 'webrtc',
        'compressor': 'Compressor',
        'gain': 'Gain',
        'source' : 'WEBRTC',
        'path' : 'https://stream.brony.eu/event/fullscreen.html?app=pony&type=webrtc&station=',
        'preview': 'wss://stream3.brony.eu:3334/pony/',
        'preview_post': '?transport=tcp'
    },
    'srt': {
        'inputType' : 'srt',
        'compressor': 'Compressor',
        'gain': 'Gain',
        'source' : 'SRT',
        'path' : 'srt://syd.srt.ponyfest.horse:40000?streamid=play/',
        'preview' : 'srt://syd.srt.ponyfest.horse:40000?streamid=play/'
    },
    'rtmp': {
        'inputType' : 'rtmp',
        'compressor': 'Compressor',
        'gain': 'Gain',
        'source' : 'RTMP',
        'path' : 'rtmp://rtmp.ponyfest.horse/live/',
        'preview' : 'wss://previews.stream-control.ponyfest.horse/stream/'
    },
    'prerecord': {
        'inputType' : 'video',
        'compressor': 'Compressor',
        'gain': 'Gain',
        'source' : 'PreRecord',
        'path' : 'C:/Users/Administrator/Desktop/obs/Prerecs/',
        'preview' : ''
    }
}

interface SceneMap {
    [key: string]: {
        scene: string,
    }}

export const WATERMARK: SceneMap = {
    'white': {
        'scene': 'White Logo'
    },
    'black': {
        'scene': 'Black Logo'
    }
}





